import React, {useEffect, useState} from "react";

import reportsEn from './reports.en.md';
import Kitchen from "./gallery/kitchen/Kitchen";
import Gabions from "./gallery/gabions/Gabions";

const Reports: React.FC = (props) => {

    let file = reportsEn;
    const [markdown, setMarkdown] = useState<string>();

    useEffect(() => {
        fetch(file)
            .then(res => {
                return res.text();
            })
            .then((text) => {
                // console.log(text);
                setMarkdown(text);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);
    return (
        <>
            <Kitchen/>
            <Gabions/>
        </>
    );
};
export default Reports;

