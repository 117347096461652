import {ImagesArray} from "../../../../../types/imageGalleryProps";

const kitchenImagesArray: ImagesArray = [
    {original: '/images/kitchen/kitchen10.jpg'},
    {original: '/images/kitchen/kitchen11.jpg'},
    {original: '/images/kitchen/kitchen12.jpg'},
    {original: '/images/kitchen/kitchen13.jpg'},
    {original: '/images/kitchen/kitchen14.jpg'},
    {original: '/images/kitchen/kitchen15.jpg'},
    {original: '/images/kitchen/kitchen16.jpg'},
    {original: '/images/kitchen/kitchen17.jpg'},
    {original: '/images/kitchen/kitchen18.jpg'},
    {original: '/images/kitchen/kitchen19.jpg'},
    {original: '/images/kitchen/kitchen20.jpg'},
    // {original: '/images/kitchen/kitchen22.jpg'},
    {original: '/images/kitchen/kitchen23.jpg'},
    {original: '/images/kitchen/kitchen24.jpg'},
    {original: '/images/kitchen/kitchen25.jpg'},
    {original: '/images/kitchen/kitchen26.jpg'},
    {original: '/images/kitchen/kitchen27.jpg'},
    {original: '/images/kitchen/kitchen28.jpg'},
    {original: '/images/kitchen/kitchen29.jpg'},
    {original: '/images/kitchen/kitchen30.jpg'},
    {original: '/images/kitchen/kitchen31.jpg'},
    {original: '/images/kitchen/kitchen32.jpg'},
];

export default kitchenImagesArray;