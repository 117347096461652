import React from "react";

import gabionsEn from './gabions.en.md';
import gabionsDe from './gabions.de.md';
import gabionsUa from './gabions.ua.md';
import gabionsRu from './gabions.ru.md';
import MarkdownText from "../../../../MarkdownText";
import {Header, Segment} from "semantic-ui-react";
import GalleryInReports from "../GalleryInReports";
import gabionsImagesArray from "../gabions/gabionsImagesArray";
import {useTranslation} from "react-i18next";

const Gabions: React.FC = () => {

    const {t} = useTranslation();

    const markdownFile = {
        en: gabionsEn,
        de: gabionsDe,
        ua: gabionsUa,
        ru: gabionsRu,
    };

    return (
        <Segment>
            <Header as={'h2'}>{t('reports.gabions')}</Header>
            <MarkdownText markdownFile={markdownFile}/>
            <GalleryInReports imagesArray={gabionsImagesArray}/>
        </Segment>
    );

}

export default Gabions;