// see:
// https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import {i18nextPlugin} from 'translation-check';
// import {showTranslations} from 'translation-check';

import translationEn from './translationEn.json';
import translationDe from './translationDe.json';
import translationRu from './translationRu.json';
import translationUa from './translationUa.json';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // https://github.com/locize/translation-check
    // Then open your app or website and add ?showtranslations in your url, i.e. http://localhost:3000?showtranslations.
    // .use(i18nextPlugin)
    .init({
            debug: true,
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            resources: {
                en: translationEn,
                de: translationDe,
                ua: translationUa,
                ru: translationRu,
            },
        }
    );


export default i18n;
