import React, {useState, useEffect} from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {Segment, Image, Grid, Header, Table} from 'semantic-ui-react';

import homePageEn from './donate.en.md';
import {useTranslation} from "react-i18next";
import PayPalDonate from "./PayPalDonate";

const Donate: React.FC = (props) => {

    // https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb
    const {t, i18n} = useTranslation();

    let file = homePageEn;

    const [markdown, setMarkdown] = useState<string>();
    useEffect(() => {
        fetch(file)
            .then(res => {
                return res.text();
            })
            .then((text) => {
                // console.log(text);
                setMarkdown(text);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    return (
        <>

            <Header as='h1'>{t('donate.donate')}</Header>

            <Header as={'h3'}>PAYPAL</Header>
            <PayPalDonate/>

            <Header as={'h3'}>{t('donate.bitcoin')}</Header>

            <a href={'https://blockchair.com/bitcoin/address/1HPrfxQxaWqsFV9V6BbEGxmfRoENqb2c6L'}
               target={'_blank'}
               rel={'noopener'}
            >
                1HPrfxQxaWqsFV9V6BbEGxmfRoENqb2c6L
            </a>


            <Header as={'h3'}>{t('donate.ethereum')}</Header>

            <a href={'https://etherscan.io/address/0x660ac84148c7a5d8026244e8a21f7b717fd2ab6b'}
               target={'_blank'}
               rel={'noopener'}
            >
                0x660ac84148c7a5d8026244e8a21f7b717fd2ab6b
            </a>

            <Header as={'h3'}>EURO (SEPA)</Header>
            <Table>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Bank:</Table.Cell>
                        <Table.Cell>Sparkasse Hilden-Ratingen-Velbert</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>BIC:</Table.Cell>
                        <Table.Cell>WELADED1VEL</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t('donate.eur.beneficiary')}:</Table.Cell>
                        <Table.Cell>Frieden für Ukraine e.V.</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>IBAN:</Table.Cell>
                        <Table.Cell>DE03 3345 0000 0034 3717 99</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Header as={'h3'}>UAH</Header>
            <Table>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Bank:</Table.Cell>
                        <Table.Cell>АТ КБ "ПРИВАТБАНК"</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t('donate.eur.beneficiary')}:</Table.Cell>
                        <Table.Cell>ГО ГРОМАДСЬКИЙ ХАБ</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t('donate.uah.code')}:</Table.Cell>
                        <Table.Cell>41334026</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t('donate.uah.iban')}:</Table.Cell>
                        <Table.Cell>UA243052990000026000026705036</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <br/>
            <div>{t('donate.info')}</div>

            {/*
            {markdown ?
                <ReactMarkdown
                    children={markdown}
                    remarkPlugins={[remarkGfm]}
                />
                : null
            }
*/}


        </>
    );
};
export default Donate;

