import React, {useState, useEffect} from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {Segment, Image, Grid} from 'semantic-ui-react';

import deUa from '../../../assets/images/de-ua.jpg';

import aboutEn from './about.en.md';
import aboutDe from './about.de.md';
import aboutUa from './about.ua.md';
import aboutRu from './about.ru.md';
import MarkdownText from "../../MarkdownText";

const AboutPage: React.FC = (props) => {

    const markdownFile = {
        en: aboutEn,
        de: aboutDe,
        ua: aboutUa,
        ru: aboutRu,
    };

    return (
        <Segment>
            <MarkdownText markdownFile={markdownFile}/>
        </Segment>
    );
};
export default AboutPage;
