import React from "react";
import './imageGallery.css';
// https://github.com/xiaolin/react-image-gallery
import ImageGallery from 'react-image-gallery';
import {ImageGalleryProps} from "../../../../types/imageGalleryProps";

const GalleryInReports: React.FC<ImageGalleryProps> = (props) => {
    return (
        <>
            <ImageGallery
                items={props.imagesArray}
                showThumbnails={false}
                showPlayButton={false}
                showBullets={true}
                showIndex={true}
            />
        </>
    );
};

export default GalleryInReports;