import React from "react";

const PayPalDonate: React.FC = () => {
    return (
        <>
            <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="EHV6FL6N7E8EE"/>
                <input type="image" src="https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif"
                    // border="0"
                       name="submit" title="PayPal - The safer, easier way to pay online!"
                       alt="Donate with PayPal button"/>
                <img alt=""
                    // border="0"
                     src="https://www.paypal.com/en_DE/i/scr/pixel.gif" width="1" height="1"/>
            </form>

        </>
    );
}

export default PayPalDonate;
