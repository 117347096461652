import React from "react";
import {Segment} from 'semantic-ui-react';
import Donate from "../donate/Donate";

import homePageEn from './homePage.en.md';
import homePageDe from './homePage.de.md';
import homePageUa from './homePage.ua.md';
import homePageRu from './homePage.ru.md';
import MarkdownText from "../../MarkdownText";

const HomePage: React.FC = (props) => {

    return (
        <>
            <Segment>
                <MarkdownText markdownFile={{
                    en: homePageEn,
                    de: homePageDe,
                    ua: homePageUa,
                    ru: homePageRu,
                }}
                />
            </Segment>
            <Segment>
                <Donate/>
            </Segment>


        </>
    );
};
export default HomePage;

