import React from 'react';
import {Segment} from "semantic-ui-react";
import {faCopyright} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './footer.css';
import packageJson from '../../../package.json';

const Footer: React.FC = (props) => {
    return (
        <>
            <Segment className={'pageFooter'}>
                <div>
                    Social: &nbsp;&nbsp;
                    <a href={'https://twitter.com/Frieden_UA'}
                       target={'_blank'}
                    >
                        <i className="twitter icon"></i>
                    </a>&nbsp;&nbsp;
                    <a href={'https://www.facebook.com/friedenua/'}
                       target={'_blank'}
                    >
                        <i className="facebook icon"></i>
                    </a>&nbsp;&nbsp;
                    <a href={'https://www.instagram.com/frieden_ukraine/'}
                       target={'_blank'}
                    >
                        <i className="instagram icon"></i>
                    </a>

                </div>

                {/*<hr/>*/}
                {/*<p>webapp version:&nbsp;&nbsp;<span>{packageJson.version}</span></p>*/}
                <br/>
                <FontAwesomeIcon icon={faCopyright}/>
                &nbsp;&nbsp;
                2022, &nbsp; Frieden für Ukraine e.V.


            </Segment>
            &nbsp;
        </>
    );
};

export default Footer;

