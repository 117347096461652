import React from 'react';
import NavigationMenu from "./NavigationMenu";

const PageHeader: React.FC = (props) => {
    return (
        <header>
            <NavigationMenu/>
        </header>
    );
};
export default PageHeader;

