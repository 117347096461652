import React from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import urlPrefixForRouter from "../../router/urlPrefixForRouter";
import {faFileSignature, faHome, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Image, Menu} from "semantic-ui-react";
import {useTranslation, Trans} from 'react-i18next';
import deUa from '../../assets/images/de-ua.jpg';
import logo from '../../assets/images/logo_400x400.jpg'
import SelectLanguage from "./SelectLanguage";

const NavigationMenu: React.FC = () => {

    let imgSrc = logo;
    const urlPrefix = urlPrefixForRouter();

    const {t} = useTranslation();

    return (

        <Menu
            stackable={true}
            // inverted={true}
        >

            <Menu.Item>
                <Image
                    src={imgSrc}
                    size={'tiny'} // 'mini' , 'tiny' , 'small'
                    centered={true}
                    circular={true}
                    // avatar={true}

                />
                <br/>
            </Menu.Item>

            <NavLink to={urlPrefix + '/'} className={'ui item'}>
                <FontAwesomeIcon icon={faHome}/>
                &nbsp;&nbsp;
                {t('headerMenu.home')}
            </NavLink>

            <NavLink to={urlPrefix + '/about'} className={'ui item'}>
                <FontAwesomeIcon icon={faInfoCircle}/>
                &nbsp;&nbsp;
                {t('headerMenu.aboutUs')}
            </NavLink>

            <NavLink to={urlPrefix + '/reports'} className={'ui item'}>
                <FontAwesomeIcon icon={faFileSignature}/>
                &nbsp;&nbsp;
                {t('headerMenu.activities')}
            </NavLink>

            <Menu.Item position={'right'}>
                <SelectLanguage/>
            </Menu.Item>

            {/*&nbsp;&nbsp;*/}
            {/*<NavLink to={urlPrefix + '/directContractDocs'} className={'ui item'}>*/}
            {/*    <FontAwesomeIcon icon={faFileSignature}/>*/}
            {/*    Contract Docs*/}
            {/*</NavLink>*/}

            {/*&nbsp;&nbsp;*/}
            {/*<NavLink to={urlPrefix + '/tests'}>*/}

            {/*    <FontAwesomeIcon icon={faFlask}/>*/}
            {/*    Tests*/}
            {/*</NavLink>*/}
        </Menu>

    );
}

export default NavigationMenu;
