import React from "react";
import {Dropdown} from "semantic-ui-react";
import {useTranslation} from 'react-i18next';
import jsonStringKeyValueType from "../../types/jsonStringKeyValue";

const SelectLanguage: React.FC = () => {

    // https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb
    const {i18n} = useTranslation();
    const defaultLanguage = i18n.language.slice(0, 2);

    // see:
    // https://react.semantic-ui.com/modules/dropdown/#types-search-selection
    const options = [
        {
            key: 'en',
            text: 'English',
            value: 'en',
            flag: 'uk',
        }, {
            key: 'de',
            text: 'Deutsch',
            value: 'de',
            flag: 'de',
        },
        {
            key: 'ua',
            text: 'Українська',
            value: 'ua',
            flag: 'ua',
        },
        {
            key: 'ru',
            text: 'Русский',
            value: 'ru',
            flag: 'ru',
        }
    ];

    const Lng: jsonStringKeyValueType = {
        'English': 'en',
        'Deutsch': 'de',
        'Українська': 'ua',
        'Русский': 'ru',
    }

    const handleOnChange = (event: React.SyntheticEvent<HTMLElement, Event>) => {
        // console.log(event);
        // console.log(event.currentTarget.innerText);

        const lng = Lng[event.currentTarget.innerText];

        i18n
            .changeLanguage(lng)
            .then(() => { // logs  'i18next: languageChanged <lg>'
                // console.log(i18n.language);
            });
    }


    return (
        <>
            {/* see: https://react.semantic-ui.com/modules/dropdown/#types-inline */}
            <Dropdown
                // text={'Select Language'}
                inline={true}
                options={options}
                defaultValue={defaultLanguage}
                onChange={(event => {
                    handleOnChange(event);
                })}
            >

            </Dropdown>

            {/*<Select options={options}/>*/}
        </>
    );
}

export default SelectLanguage;