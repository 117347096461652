import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/fomantic-ui/semantic.css';
import App from './App';

// import i18n (needs to be bundled ;))
import './i18n/i18n';

import packageJson from '../package.json';
console.log(packageJson.name, ', ver.', packageJson.version);

ReactDOM.render(
    <React.StrictMode>
        {/*<Provider store={store}>*/}
        <App/>
        {/*</Provider>*/}
    </React.StrictMode>,
    document.getElementById('root')
);

