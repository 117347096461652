import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import urlPrefixForRouter from './router/urlPrefixForRouter';
import Footer from './components/footer/Footer';
import PageHeader from './components/header/PageHeader';
import HomePage from './components/pages/home/HomePage';
import UAParser from 'ua-parser-js';
import {Container} from 'semantic-ui-react';
import AboutPage from "./components/pages/about/About";
import Reports from "./components/pages/reports/Reports";

const App: React.FC = () => {
    const uaParser = new UAParser();
    console.log(uaParser.getResult());
    const urlPrefix = urlPrefixForRouter();
    return (
        <BrowserRouter>
            <Container>
                <PageHeader/>
                <Routes>
                    <Route path={urlPrefix + '/'} element={<HomePage/>}/>
                    <Route path={urlPrefix + '/about'} element={<AboutPage/>}/>
                    <Route path={urlPrefix + '/reports'} element={<Reports/>}/>
                </Routes>
                <Footer/>
            </Container>
        </BrowserRouter>
    );
}

export default App;

