import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import homePageEn from "./pages/home/homePage.en.md";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

/*
* you need to import markdown file in parent component to pass to props
* like:
* import homePageEn from './homePage.en.md';
* */
type MarkdownTextProps = {
    markdownFile: {
        en: string,
        de: string,
        ua: string,
        ru: string,
    }
};

const MarkdownText: React.FC<MarkdownTextProps> = (props) => {

    // https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb
    const {i18n} = useTranslation();

    const [markdown, setMarkdown] = useState<string>();

    useEffect(() => {

            let file: string;
            switch (i18n.language) {
                case 'de':
                    file = props.markdownFile.de;
                    break;
                case 'ua':
                    file = props.markdownFile.ua;
                    break;
                case 'ru':
                    file = props.markdownFile.ru;
                    break;
                default:
                    file = props.markdownFile.en;
            }

            fetch(file)
                .then(res => {
                    return res.text();
                })
                .then((text) => {
                    // console.log(text);
                    setMarkdown(text);
                })
                .catch(error => {
                    console.log(error);
                })
        },
        [i18n.language]
    );

    return (
        <>
            {markdown ?
                <ReactMarkdown
                    children={markdown}
                    remarkPlugins={[remarkGfm]}
                />
                : null
            }
        </>
    );
}

export default MarkdownText;