import {ImagesArray} from "../../../../../types/imageGalleryProps";

const gabionsImagesArray = [
    // {original: '/images/gabions/gabions_10.jpg'},
    // {original: '/images/gabions/gabions_11.jpg'},
    {original: '/images/gabions/gabions_12.jpg'},
    {original: '/images/gabions/gabions_13.jpg'},
    {original: '/images/gabions/gabions_14.jpg'},
    {original: '/images/gabions/gabions_15.jpg'},
    {original: '/images/gabions/gabions_16.jpg'},
    {original: '/images/gabions/gabions_17.jpg'},
    // {original: '/images/gabions/gabions_18.jpg'},
    {original: '/images/gabions/gabions_19.jpg'},
    {original: '/images/gabions/gabions_20.jpg'},
    {original: '/images/gabions/gabions_21.jpg'},
    {original: '/images/gabions/gabions_22.jpg'},
    {original: '/images/gabions/gabions_23.jpg'},
    {original: '/images/gabions/gabions_24.jpg'},
    {original: '/images/gabions/gabions_25.jpg'},
    {original: '/images/gabions/gabions_26.jpg'},
    {original: '/images/gabions/gabions_27.jpg'},
    {original: '/images/gabions/gabions_28.jpg'},
    {original: '/images/gabions/gabions_29.jpg'},
    {original: '/images/gabions/gabions_30.jpg'},
    {original: '/images/gabions/gabions_31.jpg'},
    {original: '/images/gabions/gabions_32.jpg'},
    {original: '/images/gabions/gabions_33.jpg'},
    {original: '/images/gabions/gabions_34.jpg'},
    {original: '/images/gabions/gabions_35.jpg'},
    // {original: '/images/gabions/gabions_36.jpg'},
    // {original: '/images/gabions/gabions_37.jpg'},
    {original: '/images/gabions/gabions_38.jpg'},
    {original: '/images/gabions/gabions_39.jpg'},
    {original: '/images/gabions/gabions_40.jpg'},
    {original: '/images/gabions/gabions_41.jpg'},
    {original: '/images/gabions/gabions_42.jpg'},
];

export default gabionsImagesArray;