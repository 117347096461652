import React from "react";
import {Header, Segment} from "semantic-ui-react";
import kitchenImagesArray from "./kitchenImagesArray";
import GalleryInReports from "../GalleryInReports";
import {useTranslation} from "react-i18next";
import kitchenEn from './kitchenEn.md';
import kitchenDe from './kitchenDe.md';
import kitchenUa from './kitchenUa.md';
import kitchenRu from './kitchenRu.md';
import MarkdownText from "../../../../MarkdownText";

const Kitchen: React.FC = () => {

    const {t} = useTranslation();

    const markdownFile = {
        en: kitchenEn,
        de: kitchenDe,
        ua: kitchenUa,
        ru: kitchenRu,
    }

    return (
        <Segment>
            <Header as={'h2'}>{t('reports.kitchen')}</Header>
            <MarkdownText markdownFile={markdownFile}/>
            <GalleryInReports imagesArray={kitchenImagesArray}/>
        </Segment>
    );
}

export default Kitchen;